// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import Hcont from '../components/Hcont';
import {
  Button,
  Layout,
  LinkedButton,
  SectionTestimonial,
  SectionQuickPrompt,
  MarkdownPs,
  Section,
  TextHeading,
  TextP,
} from '../components';

import type { PagePartnerQuery } from '../__generated__/PagePartnerQuery';

type Props = $ReadOnly<{|
  data: PagePartnerQuery,
|}>;

const logoWidth = 260;
const logoHeight = 80;

const PagePartners = ({ data: { page, testimonial } }: Props) => {
  if (!page) throw new Error('About missing from CMS');

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Section>
        <StyledLeadingText>
          <TextHeading level={1} tag="h1">
            {page.heading}
          </TextHeading>
          {page.leadingText && <MarkdownPs html={page.leadingText} />}
          <LinkedButton label={page.buttonLabel} link={page.buttonLink} />
        </StyledLeadingText>
      </Section>
      {testimonial && (
        <SectionTestimonial
          testimonial={testimonial}
          backgroundColor="darkBlue"
        />
      )}
      <Hcont>
        <StyledPartners>
          {(page.mainPartners || []).filter(Boolean).map(partner => {
            const url = partner.logo?.url;
            const src =
              url && `${url}?fit=max&w=${logoWidth * 2}&h=${logoHeight * 2}`;

            return (
              <div key={partner.id}>
                <StyledLogo>
                  <img src={src} alt={partner.name} />
                </StyledLogo>
                <TextP small>{partner.description}</TextP>
              </div>
            );
          })}
        </StyledPartners>
      </Hcont>
      <SectionQuickPrompt
        text="Find out how to become a Partner"
        button={<Button label="Get in touch" to="/contact" />}
      />
    </Layout>
  );
};
export default PagePartners;

export const query = graphql`
  query PagePartnerQuery {
    page: datoCmsPagePartner {
      heading
      leadingText
      buttonLabel
      buttonLink
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mainPartners {
        id
        name
        description
        logo {
          id
          url
        }
        link
      }
    }
    testimonial: datoCmsTestimonial(identifier: { eq: "partners_1" }) {
      ...SectionTestimonial_testimonial
    }
  }
`;

const StyledLogo = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(logoHeight)};

  > img {
    display: block;
    max-width: ${rem(logoWidth)};
    max-height: ${rem(logoHeight)};
  }
`;

const StyledPartners = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  > div {
    display: inline-block;
    width: 28%;
    margin: ${rem(Dimen.spacing / 2)};
  }
  @media (max-width: 720px) {
    > div {
      display: block;
      width: 100%;
    }
  }
`;

const StyledLeadingText = styled('div')`
  padding: ${rem(Dimen.spacing * 2)} 0;
  text-align: center;
`;
